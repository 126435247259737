<template>
  <div>
    <hero-section />
    <what-we-do />
    <our-process />
    <solutions-we-offer />
    <contact-us />
  </div>
</template>

<script>
import ContactUs from "../components/sections/ContactUs.vue";
import HeroSection from "../components/sections/HeroSection.vue";
import OurProcess from "../components/sections/OurProcess.vue";
import SolutionsWeOffer from "../components/sections/SolutionsWeOffer.vue";
import WhatWeDo from "../components/sections/WhatWeDo.vue";
// @ is an alias to /src

export default {
  name: "Home",
  components: {
    HeroSection,
    WhatWeDo,
    OurProcess,
    SolutionsWeOffer,
    ContactUs,
  },
};
</script>

<template>
  <v-container>
    <div class="my-16" id="contact_us">
      <div class="heading text-center mb-10">
        Contact Us
      </div>
      <v-row>
        <v-col cols="12" sm="4">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-subtitle class="fg-color subtitle-1">Phone Number</v-list-item-subtitle><br />
              <v-list-item-title class="title fg-color">+1(647)202-1226, +1(647)982-3567</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-subtitle class="fg-color subtitle-1">Email</v-list-item-subtitle><br />
              <v-list-item-title class="title fg-color">
                info@bareleaf.ca
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="8">
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  label="Enter your name*"
                  color="brown darken-4"
                  v-model="name"
                  :rules="nameRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  label="Enter your email*"
                  color="brown darken-4"
                  v-model="email"
                  :rules="emailRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  outlined
                  label="Your message*"
                  v-model="message"
                  color="brown darken-4"
                  :rules="messageRules"
                ></v-textarea>
              </v-col>
            </v-row>
            <div class="d-flex justify-end">
              <v-btn
                class="px-6 py-5 bg-color white--text right primary-font"
                :disabled="!valid"
                :loading="loading"
                @click="onSubmit"
                >Contact Us</v-btn
              >
            </div>
          </v-form>
        </v-col>
      </v-row>
    </div>
    <v-snackbar v-model="snackbar" :color="color" top centered>
      {{ text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { cloudFireStore } from "../../utils/firebase";
import moment from "moment";
export default {
  data: () => ({
    name: "",
    email: "",
    message: "",
    snackbar: false,
    color: "success",
    text: "",
    valid: false,
    loading: false,

    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 20) || "Name must be less than 20 characters",
    ],
    emailRules: [(v) => !!v || "E-mail is required", (v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
    messageRules: [(v) => !!v || "Please provide your message"],
  }),
  methods: {
    async onSubmit() {
      this.loading = true;
      await cloudFireStore
        .collection("Contact")
        .add({
          name: this.name,
          email: this.email,
          message: this.message,
          date: moment().format("lll"),
          source: "bareleaf-solutions",
        })
        .then(() => {
          this.loading = false;
          this.snackbar = true;
          this.text = "Thank you for your message. We'll get back to you soon";
          this.color = "success";
          this.$refs.form.reset();
        })
        .catch(() => {
          this.loading = false;
          this.snackbar = true;
          this.text = "Something went wrong. Please try again later.";
          this.color = "red";
        });
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: #43c200 !important;
}
</style>

<template>
  <v-container>
    <div class="my-16" id="what_we_do">
      <div class="heading text-center mb-10">What We Do</div>
      <v-row>
        <v-col cols="12" md="6">
          <div class="text-lg-h5 fg-color primary-font">
            Bareleaf Solutions is dedicated to unleash the potential of your company’s brand. With our expertise in the
            industry we can ensure full creative and digital services to fulfill our client’s vision.
          </div>
          <v-btn class="primary-font bg-color white--text px-6 py-6 mt-8 custom-radius" @click="$scrollTop('contact_us')"
            >Build your brand</v-btn
          >
        </v-col>
        <v-col cols="12" md="6">
          <v-img contain src="../../assets/images/what_we_do.svg" height="400"></v-img>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.text-lg-h5 {
  line-height: 212%;
  font-family: "Poppins", sans-serif !important;
}

</style>

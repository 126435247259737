<template>
  <v-container>
    <div class="my-16" id="solutions_we_offer">
      <div class="heading text-center mb-10">
        Solutions We Offer
      </div>
      <v-row>
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12" sm="6" v-for="info in solutions" :key="info.name">
              <v-card class="card-color bg-color" rounded="lg" hover>
                <v-card-title class="d-flex justify-center white-text primary-font"> {{ info.name }} </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <div class="text-lg-h5 fg-color primary-font">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet dictum maecenas vestibulum, aliquet aliquam
            luctus pellentesque. Cras ultricies purus adipiscing suspendisse nibh in. Sed amet ante morbi ultricies
            dolor turpis dictum. Amet, nibh dui proin tellus orci a, adipiscing sagittis. Mauris suspendisse amet amet,
            urna.
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    solutions: [{ name: "Web Development" }, { name: "Web Hosting" }, { name: "App Design" }, { name: "Logo Design" }],
  }),
};
</script>

<style lang="scss" scoped>
.text-lg-h5 {
  line-height: 212%;
  font-family: "Poppins", sans-serif !important;
}
</style>

<template>
  <div class="hero-container mb-16" id="home">
    <img src="../../assets/images/blob_circle.svg" alt="blob1" class="blob-circle" />
    <img src="../../assets/images/blob_circle2.svg" alt="blob2" class="blob-circle2" />
    <v-container>
      <v-row>
        <v-col cols="12" md="6" lg="8" class="margin-top">
          <div class="overline fg-color">make <span class="green--text darken-4">bangladesh</span> popular once again</div>
          <div class="hero-text primary-font fg-color">
            Connecting Local Companies With Suppliers From The World
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="4" class="mt-6">
          <v-img contain src="../../assets/images/hero-image.svg"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.hero-container {
  position: relative;
  width: 100%;
  height: 60vh;
  .blob-circle {
    position: absolute;
    left: -3%;
    top: 60%;
  }
  .blob-circle2 {
    position: absolute;
    right: -2%;
    top: -5%;
  }
}
.margin-top {
  margin-top: 4em;
}
.hero-text {
  font-size: 3.5em;
  font-weight: 700;
}
@media screen and (max-width: 960px) {
  .hero-container {
    height: auto;
  }
  .hero-text {
    font-size: 3em;
    font-weight: 800;
  }
  .margin-top {
    margin-top: 10px;
  }
}

@media screen and (max-width: 480px) {
  .hero-text {
    font-size: 2.3em;
    font-weight: 600;
  }
  .blob-circle,
  .blob-circle2 {
    display: none;
  }
}
</style>

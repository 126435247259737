<template>
  <v-footer padless>
    <v-row class="footer-color" justify="center" no-gutters>
      <!-- <v-btn v-for="link in links" :key="link" color="white" text class="my-2 primary-font">
        {{ link }}
      </v-btn> -->
      <v-col class="footer-color py-4 text-center white--text" cols="12">
        {{ new Date().getFullYear() }} — &#xa9; Bareleaf
        <br />
        Made with &#9829; by
        <a class="white--text font-weight-bold" rel="noopener" href="https://teamtigers.tech" target="_blank"
          >TeamTigers</a
        >
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
export default {
  data: () => ({
    links: ["Home", "About Us", "Services"],
  }),
};
</script>

<style lang="scss" scoped>
.footer-color {
  background-color: #200d00 !important;
}

a {
  text-decoration: none;
}
</style>

<template>
  <v-app-bar app class="navbar-color" dark hide-on-scroll>
    <router-link to="/">
      <div class="d-flex align-center">
        <v-img
          alt="Bareleaf Logo"
          class="shrink mr-2"
          contain
          src="https://firebasestorage.googleapis.com/v0/b/bareleaf-admin.appspot.com/o/logo-removebg.png?alt=media&token=ed98e02a-ae33-4abf-b217-c959eff8e116"
          transition="scale-transition"
          width="60"
        />
      </div>
    </router-link>
    <v-toolbar-title class="primary-font">Bareleaf</v-toolbar-title>

    <v-spacer></v-spacer>
    <!-- SHOW FOR MEDIUM AND UPPER DEVICES -->
    <v-btn
      v-for="item in navbarItems"
      :key="item.itemName"
      @click="$scrollTop(item.scrollToSection)"
      text
      depressed
      class="primary-font d-none d-md-flex"
    >
      <span class="mr-2">{{ item.itemName }}</span>
    </v-btn>

    <!-- SHOW FOR SMALLER AND BELOW DEVICES -->
    <div class="d-flex d-md-none">
      <v-menu left bottom rounded="lg" transition="scale-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" aria-label="menu">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>

        <v-list v-for="item in navbarItems" :key="item.itemName">
          <v-list-item @click="$scrollTop(item.scrollToSection)">
            <v-list-item-title class="primary-font">{{ item.itemName }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  data: () => ({
    navbarItems: [
      { scrollToSection: "home", itemName: "Home" },
      { scrollToSection: "what_we_do", itemName: "What We Do" },
      { scrollToSection: "our_process", itemName: "Our Process" },
      { scrollToSection: "solutions_we_offer", itemName: "Solutions We Offer" },
      { scrollToSection: "contact_us", itemName: "Contact Us" },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.navbar-color {
  background-color: #200d00 !important;
}
</style>

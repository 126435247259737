<template>
  <v-app>
    <navbar />
    <v-main class="custom-color">
      <router-view></router-view>
    </v-main>
    <common-footer />
  </v-app>
</template>

<script>
import CommonFooter from "./components/CommonFooter.vue";
import Navbar from "./components/Navbar.vue";

export default {
  name: "App",

  components: {
    Navbar,
    CommonFooter,
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
$primary-color: #200D00;
body {
  overflow: hidden;
}
.custom-color {
  background-color: #F9F4E0 !important;
}
.fg-color {
  color: $primary-color !important;
}
.bg-color {
  background-color: $primary-color !important;
}

.white-text {
  color: #efefefef !important;
}
.primary-font {
  font-family: "Poppins", sans-serif;
}

.custom-radius {
  border-radius: 8px;
}

.heading {
  color: $primary-color;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 2.8em;
}
</style>

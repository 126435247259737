<template>
  <div>
    <v-container>
      <div class="my-16" id="our_process">
        <div class="heading text-center mb-10">Our Process</div>
        <v-row>
          <v-col cols="12" sm="6" md="4" lg="3" v-for="info in cardInfo" :key="info.title">
            <v-card flat class="card-style pt-2" rounded="lg">
              <v-img contain :src="info.image" height="100" class="card-image">
                <template v-slot:placeholder>
                  <v-sheet color="grey lighten-4">
                    <v-skeleton-loader class="mx-auto" type="image"></v-skeleton-loader>
                  </v-sheet>
                </template>
              </v-img>

              <v-card-title class="d-flex justify-center primary-font fg-color font-weight-bold">
                {{ info.title }}
              </v-card-title>
              <v-card-text class="text-center primary-font fg-color"> {{ info.text }} </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    cardInfo: [
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/bareleaf-admin.appspot.com/o/findings.svg?alt=media&token=9c376066-dbd4-40bb-9603-526a156094a4",
        title: "Discovery",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ornare eget fringilla lacus, congue.",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/bareleaf-admin.appspot.com/o/plan_design.svg?alt=media&token=3733678c-4af3-4a33-aa3a-4dd09388f026",
        title: "Plan & Design",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ornare eget fringilla lacus, congue.",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/bareleaf-admin.appspot.com/o/development.svg?alt=media&token=e94c170e-47d5-4401-aa83-eda56301fec0",
        title: "Development",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ornare eget fringilla lacus, congue.",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/bareleaf-admin.appspot.com/o/success.svg?alt=media&token=e2257fbf-9063-4575-b139-85899632f78f",
        title: "Success",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ornare eget fringilla lacus, congue.",
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.card-style {
  box-shadow: none !important;
  transition: all 0.3s;
  background-color: #f9f4e0 !important;
  &:hover {
    // -webkit-box-shadow: 0px 0px 15px 10px rgba(188, 188, 188, 0.59);
    // box-shadow: 0px 0px 15px 10px rgba(188, 188, 188, 0.59);
    box-shadow: 1px 1px 0px rgb(209, 209, 209), 2px 2px 0px rgb(209, 209, 209), 3px 3px 0px rgb(209, 209, 209),
      4px 4px 0px rgb(209, 209, 209), 5px 5px 0px rgb(209, 209, 209), 6px 6px 0px rgb(209, 209, 209) !important;
    .card-image {
      position: relative;
      animation: myanimation 1.5s infinite alternate ease-in-out;

      @keyframes myanimation {
        0% {
          transform: scale(1);
        }
        100% {
          transform: scale(0.8);
        }
      }
    }
  }
}
</style>
